import Choices from 'choices.js/public/assets/scripts/choices.js'
import _ from 'lodash'
import { ready } from './entries/utils'

// This was an attempt allow the user to switch vercel accounts and instantly get the repos from the new account without having to refresh the page
// but it's not working yet, so if the vercel account is changed, the website must be saved first and then the page reloaded before you can select a vercel repo/project

window.vercelChoiceInstance = undefined

const searchVercelRepos = (query, dealershipId, websiteId, vercelAccountId) =>
  fetch(
    `/dealerships/${dealershipId}/websites/${websiteId}/vercel_repos.json?query=${query}&vercel_account_id=${vercelAccountId}`
  )

const handleSearchEvent = _.debounce(async (e) => {
  const { value } = e.detail

  const vercelSelect = document.querySelector('.js-choice-vercel-projects')
  const dealershipId = vercelSelect.getAttribute('data-dealership-id')
  const websiteId = vercelSelect.getAttribute('data-website-id')
  const vercelAccountId = vercelSelect.getAttribute('data-vercel-account-id')

  vercelChoiceInstance.setChoices(async () => {
    try {
      const items = await searchVercelRepos(value, dealershipId, websiteId, vercelAccountId)
      return items
        .map((item) => ({
          value: item[0],
          label: item[1],
        }))
        .json()
    } catch (err) {
      console.error(err)
    }
  })
}, 1000)

function initVercelRepoChoices() {
  const vercelSelect = document.querySelector('.js-choice-vercel-projects')

  if (vercelSelect) {
    let vercelChoice = new Choices(vercelSelect, {
      placeholder: true,
      itemSelectText: 'Select',
      addItems: false,
      allowHTML: true,
    })
    vercelChoiceInstance = vercelChoice
    vercelChoiceInstance.passedElement.element.addEventListener('search', handleSearchEvent, false)
  }
}

// TODO: Fix this so the user can change the vercel account and get the repos from the new account without having to refresh the page
// Currently we are just disabling the select and showing a message to the user to save the website first and then reload the page
$(document).on('change', '#website_vercel_account_id', function () {
  const vercelSelect = document.querySelector('.js-choice-vercel-projects')
  // Disable the select
  vercelSelect.disabled = true
  // Show the message
  $('.js-vercel-account-message').removeClass('d-none')

  const dealershipId = vercelSelect.getAttribute('data-dealership-id')
  const websiteId = vercelSelect.getAttribute('data-website-id')
  vercelChoiceInstance.passedElement.element.removeEventListener('search', handleSearchEvent)
  vercelChoiceInstance.clearChoices()
  vercelChoiceInstance.destroy()

  $('.js-choice-vercel-projects').attr('data-vercel-account-id', $(this).val())
  vercelChoiceInstance.passedElement.element.addEventListener('search', handleSearchEvent, false)
  vercelChoiceInstance.init()
})

ready(function () {
  initVercelRepoChoices()
})

document.addEventListener('vanilla-nested:fields-added', function (e) {
  initVercelRepoChoices()
})
