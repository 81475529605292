import flatpickr from 'flatpickr'
import moment from 'moment'
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect'
import { ready } from './entries/utils'

function loadFlatpickr() {
  flatpickr('.flatpickr', {
    allowInput: true,
    dateFormat: 'd/m/Y',
  })

  flatpickr('.flatpickr-month-year', {
    allowInput: true,
    altInput: true,
    plugins: [
      new monthSelectPlugin({
        dateFormat: 'd/m/Y',
        altFormat: 'm/Y',
        altInput: true,
      }),
    ],
  })

  flatpickr('.flatpickr-with-time', {
    allowInput: true,
    dateFormat: 'd/m/Y H:i',
    enableTime: true,
    minTime: '8:00',
    maxTime: '17:00',
    minDate: 'today',
    disable: [(date) => date.getDay() === 0 || date.getDay() === 6],
  })

  let select = document.getElementById('flatpicker-date-range')
  let locationSelect = document.querySelector('select#analytics_location_select_locations')
  if (!select) return
  let url = document.getElementById('flatpicker-date-range').getAttribute('url')

  flatpickr('#flatpicker-date-range', {
    allowInput: true,
    dateFormat: 'd/m/y',
    maxDate: moment().format('DD/MM/YY'),
    mode: 'range',
    onChange: (selectedDates, dateStr, instance) => {
      let start = Date.parse(selectedDates[0]) / 1000
      // We want leads to be inclusive of dates
      // So for example 1/1/2000 - 5/1/2000 should include leads up to end of 5/1/2000
      let end = Date.parse(selectedDates[1]) / 1000 + 60 * 60 * 24
      let data = {
        start: start,
        end: end,
        timeframe_name: `from ${dateStr}`,
      }
      if (locationSelect) {
        data['location_id'] = locationSelect.value
      }
      let object_type = select.getAttribute('object_type') + '_id'
      data[object_type] = select.getAttribute('object_id')
      if (start && end) {
        let bases = document.querySelectorAll('#lead-charts, #leads')
        bases.forEach((base) => {
          base.innerHTML = '<div class="loadingBoxes"></div><div class="loadingBoxes"></div>'
        })
        addLoadingBoxes()

        Rails.ajax({
          url: url,
          type: 'get',
          data: new URLSearchParams(data).toString(),
          accept: 'js',
          dataType: 'script',
          success: function (data) {},
          error: function (data) {},
        })
      }
    },
  })
}

window.loadFlatpickr = loadFlatpickr

ready(function () {
  loadFlatpickr()
})

// updates flatpickr when user selects item from the dropdown list
ready(function () {
  let buttons = document.querySelectorAll('.modify-flatpickr')
  buttons.forEach((button) => {
    button.addEventListener('click', (e) => {
      let pickr = document.getElementById('flatpicker-date-range')._flatpickr
      var date1 = e.target.getAttribute('start')
      var date2 = e.target.getAttribute('end')
      // the second parameter ("true") --> triggerChange
      pickr.setDate([date2, date1], true)
    })
  })
})

ready(function () {
  let locationSelect = document.querySelector('select#analytics_location_select_locations')
  let datePickr = document.getElementById('flatpicker-date-range')
  if (locationSelect) {
    locationSelect.addEventListener('change', function (e) {
      let pickr = datePickr._flatpickr
      if (pickr.selectedDates.length > 0) {
        pickr.setDate(pickr.selectedDates, true)
      } else {
        pickr.setDate(JSON.parse(datePickr.getAttribute('defaultDates')), true)
      }
    })
  }
})
