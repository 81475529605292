const AddStepTooltip = {
  content: (
    <div className="small">
      <p className="mb-0">Use this to add a new step to your form.</p>
    </div>
  ),
}

const AddFieldTooltip = {
  content: (
    <div className="small">
      <p className="mb-0">Use this to add a new field to your form.</p>
    </div>
  ),
}

const FormTypeTooltip = {
  content: (
    <div className="small">
      <p className="mb-1">These are the different options available for form types:</p>
      <ul className="mb-0">
        <li>
          <span className="font-weight-bold">Single Step</span> - a simple, one-page form with all
          fields displayed at once.
        </li>
        <li>
          <span className="font-weight-bold">Multi Step</span> - a series of steps and their
          associated fields, that users progress through one-by-one after they have completed all
          the required fields. This can be used to generate partial leads on step change (
          <span className="font-weight-bold">
            partial leads can only be generated once the user has provided values for the core
            fields
          </span>
          ).
        </li>
      </ul>
    </div>
  ),
}

const LeadCategoryTooltip = {
  content: (
    <div className="small">
      <p className="mb-0">
        These are the different options available for what the lead category will be assigned as for
        any leads captured via this form. The lead category can be used to help categorise leads in
        the Dealer Studio dashboard for setting up notifications.
      </p>
    </div>
  ),
}

const SkipSendToServerTooltip = {
  content: (
    <div className="small">
      <p className="mb-1">
        This is used to skip generating/updating leads when a user completes this step.{' '}
        <span className="font-weight-bold">It is recommended to leave this option unchecked.</span>
      </p>
      <p className="mb-0">
        This option is disabled for the steps preceding the step containing the core fields, as well
        as the final step of the form.
      </p>
    </div>
  ),
  variant: 'warning',
}

const CustomStepTooltip = {
  content: (
    <div className="small">
      <p className="mb-0">
        This is used by Dealer Studio developers to handle custom requests.{' '}
        <span className="font-weight-bold">It is recommended to leave this option unchecked.</span>
      </p>
    </div>
  ),
  variant: 'warning',
}

const MoveStepTooltip = {
  content: (
    <div className="small">
      <p className="mb-0">
        This is used to move the step to another position. It will automatically move the associated
        step fields to the new position as well.
      </p>
    </div>
  ),
}

const CoreFieldsTooltip = {
  content: (
    <div className="small">
      <p>These inputs are required for Dealer Studio leads and cannot be deleted.</p>
      <p className="font-weight-bold mb-0">
        Please note that any steps before the step containing the core fields will not be able to
        generate/update leads on step change.
      </p>
    </div>
  ),
  variant: 'warning',
}

const FieldTypeTooltip = {
  content: (
    <div className="small">
      <p className="mb-1">These are the different options available for field types:</p>
      <ul className="mb-0">
        <li>
          <span className="font-weight-bold">text</span> - a simple, one-line text field that
          accepts any type of text
        </li>
        <li>
          <span className="font-weight-bold">message</span> - a multi-line text box that accepts any
          type of text
        </li>
        <li>
          <span className="font-weight-bold">number</span> - a field that only accepts numbers
        </li>
        <li>
          <span className="font-weight-bold">select</span> - a select field where users can choose
          from a list of options that you define
        </li>
        <li>
          <span className="font-weight-bold">check</span> - a checkbox that can be used to allow
          users to select a true/false value, OR to force users to accept terms and conditions
        </li>
        <li>
          <span className="font-weight-bold">address</span> - an address field that provides
          auto-complete options
        </li>
        <li>
          <span className="font-weight-bold">postcode</span> - a field that only accepts valid
          Australian postcodes
        </li>
        <li>
          <span className="font-weight-bold">dob</span> - a date of birth field (dd/mm/yyyy)
        </li>
        <li>
          <span className="font-weight-bold">year</span> - a select input with the years from 1970
          to now as options
        </li>
        <li>
          <span className="font-weight-bold">currency</span> - a field that only accepts valid
          currency values (numbers and decimal points)
        </li>
        <li>
          <span className="font-weight-bold">hidden</span> - a hidden field that can be used to
          manually set particular lead values (such as location_id)
        </li>
        <li>
          <span className="font-weight-bold">locationSelect</span> - a specialised select input that
          allows you to define a list of locations (attached to your dealership) for the user to
          select from
        </li>
        <li>
          <span className="font-weight-bold">rego</span> - allows a user to enter vehicle
          registration and state details, and searches for the matching vehicle
        </li>
        <li>
          <span className="font-weight-bold">make</span> - allows the user to select from available
          makes (with those connected to the dealership being highlighted first)
        </li>
        <li>
          <span className="font-weight-bold">serviceInterval</span> - allows the user to enter a
          service interval (or select from predefined options for particular makes)
        </li>
        <li>
          <span className="font-weight-bold">dateTime</span> - allows the user to select a date and
          time from the options defined in the Dealer Studio dashboard
        </li>
        <li>
          <span className="font-weight-bold">file</span> - allows a user to upload a file (e.g. a
          resume)
        </li>
      </ul>
    </div>
  ),
}

const DuplicateFieldDataKeyTooltip = {
  content: (
    <div className="small">
      <p className="mb-0">
        This form data key is used multiple times in the form. It is very important that this value
        is unique for each field. Please update the value to be unique.
      </p>
    </div>
  ),
  variant: 'warning',
}

const FieldDataKeyTooltip = {
  content: (
    <div className="small">
      <p className="mb-1">
        This is the key that will be used to show this field on your form leads.{' '}
        <span className="text-danger">
          It is very important that this is unique and descriptive for each field.
        </span>
      </p>
      <p className="mb-0">Some important values are:</p>
      <ul className="mb-0">
        <li>
          <span className="font-weight-bold">location_id</span> - this will connect your lead with
          the associated location. This can be useful for locationSelect inputs (giving the user a
          list of locations to choose from), or for hidden inputs (automatically assigning the lead
          to a location).
        </li>
      </ul>
    </div>
  ),
}

const MultiInputOptionsTooltip = {
  content: (
    <div className="small">
      <p className="mb-0">
        Use this to add select options to the input. Users will then be able to select one of these
        options when filling out the form.
      </p>
    </div>
  ),
}

const MultiSelectOptionsTooltip = {
  content: (
    <div className="small">
      <p className="mb-0">
        Use this to add location options to the locationSelect input. Users will then be able to
        select one of these location options when filling out the form. The option they select will
        then be used to assign the lead to the selected location.
      </p>
    </div>
  ),
}

const HelpTooltip = {
  content: (
    <div className="small">
      <p className="mb-0">
        Use this to add a small help message to the field. This message will be displayed below the
        field input.
      </p>
    </div>
  ),
}

const RequiredTooltip = {
  content: (
    <div className="small">
      <p className="mb-0">
        Do you want to make this field required for the user to submit the form? If a field is
        required, the user is forced to fill it in before they can submit the form.
      </p>
    </div>
  ),
}

const MoveFieldTooltip = {
  content: (
    <div className="small">
      <p className="mb-0">
        This is used to move the field to another step. It will automatically place the field at the
        end of the selected step.
      </p>
    </div>
  ),
}

const SpecialValueTooltip = {
  content: (
    <div className="small">
      <p className="mb-0">
        Hidden inputs are used to manually set particular values on the lead. This input is used to
        set the value that will be sent with the lead.
      </p>
    </div>
  ),
}

const DefaultCheckedTooltip = {
  content: (
    <div className="small">
      <p className="mb-0">
        Use this input to set the checkbox as checked by default when the form is loaded.
      </p>
    </div>
  ),
}

const DefaultSelectedTooltip = {
  content: (
    <div className="small">
      <p className="mb-0">
        The value in this input needs to match the value of an available option.
      </p>
    </div>
  ),
  variant: 'warning',
}

const BookingTypeTooltip = {
  content: (
    <div className="small">
      <p className="mb-1">These are the different options for dateTime input types:</p>
      <ul className="mb-0">
        <li>
          <span className="font-weight-bold">Service</span> - uses the service booking settings from
          Dealer Studio
        </li>
        <li>
          <span className="font-weight-bold">Test Drive</span> - uses the test drive booking
          settings from Dealer Studio
        </li>
        <li>
          <span className="font-weight-bold">Custom</span> - allows you to define custom date and
          time options{' '}
          <span className="font-weight-bold text-danger">
            (this option won't automatically block out existing bookings for the user)
          </span>
        </li>
      </ul>
    </div>
  ),
}

export {
  AddStepTooltip,
  AddFieldTooltip,
  FormTypeTooltip,
  LeadCategoryTooltip,
  SkipSendToServerTooltip,
  CustomStepTooltip,
  MoveStepTooltip,
  CoreFieldsTooltip,
  FieldTypeTooltip,
  DuplicateFieldDataKeyTooltip,
  FieldDataKeyTooltip,
  MultiInputOptionsTooltip,
  MultiSelectOptionsTooltip,
  HelpTooltip,
  RequiredTooltip,
  MoveFieldTooltip,
  SpecialValueTooltip,
  DefaultCheckedTooltip,
  DefaultSelectedTooltip,
  BookingTypeTooltip,
}
