import '@rails/actiontext'
import './entries/photoUpload'
import './flatpickr'
import axios from 'axios'
import './address_autocomplete'
import './entries/chart'
import './entries/algolia_search'
import './entries/currency'
import './entries/choices'
import './editor/editor'
import './preorder_cars'
import './shared/stock_filters'
import '@nathanvda/cocoon'
import Trix from 'trix'
import './shared/LoadingBoxes'
import './websiteVercelSelector'
import { ready } from './entries/utils'
import * as Routes from '../routes'
import 'channels'
window.Routes = Routes

function loadExpandable() {
  let expandable_boxes = document.querySelectorAll('.expandable')
  if (expandable_boxes) {
    expandable_boxes.forEach((expandable_box) =>
      expandable_box.addEventListener('click', (e) => {
        expandable_box.classList.remove('expandable')
      })
    )
  }
}

window.loadExpandable = loadExpandable

ready(() => {
  loadExpandable()
})

ready(function () {
  const select = document.querySelector('#dynamic-make-input')
  if (select) {
    select.addEventListener('change', function (e) {
      let modelSelect = document.querySelector('select#dynamic-model-input')
      let published = select.getAttribute('published')
      let url = `/manufacturers/${e.target.value}/models/model_names.json?published=${published}`
      modelSelect.disabled = true
      axios.get(url).then((res) => {
        if (modelSelect) {
          modelSelect.innerHTML = '<option value=""></option>'
          res.data.map((model, i) => {
            modelSelect.options[i + 1] = new Option(model.name, model.id)
          })
          modelSelect.disabled = false
        }
      })
    })
  }
})

// used in Gardx Form for dynamic redbook make/model selection
ready(function () {
  const select = document.querySelector('.dynamic-redbook-make-input')
  let modelSelect = document.querySelector('.dynamic-redbook-model-input')
  if (select && modelSelect) {
    select.addEventListener('change', function (e) {
      let url = `/manufacturers/${e.target.value}/families.json`
      modelSelect.disabled = true
      axios.get(url).then((res) => {
        if (modelSelect) {
          modelSelect.innerHTML = '<option value=""></option>'
          res.data.map((model, i) => {
            modelSelect.options[i + 1] = new Option(model.name, model.name)
          })
          modelSelect.disabled = false
        }
      })
    })
  }
})

ready(function () {
  let status_selects = document.querySelectorAll('.status-select')
  let copyHoursBtn = document.querySelector('.copy-hours')

  if (status_selects) {
    status_selects.forEach((status_select) =>
      status_select.addEventListener('change', (e) => {
        let target = e.target.getAttribute('target')
        let open = document.getElementById(target + '-open')
        let close = document.getElementById(target + '-close')
        if (e.target.value === 'Closed' || e.target.value === 'By Appointment') {
          open.classList.add('d-none')
          close.classList.add('d-none')
        } else {
          open.classList.remove('d-none')
          close.classList.remove('d-none')
        }

        // Check if Monday's status is 'By Appointment' and hide the copy button
        let mondayStatus = document.querySelector('[target="day-1"]')?.value
        if (mondayStatus === 'By Appointment') {
          copyHoursBtn.classList.add('d-none')
        } else {
          copyHoursBtn.classList.remove('d-none')
        }
      })
    )
  }

  if (copyHoursBtn) {
    copyHoursBtn.addEventListener('click', (e) => {
      e.preventDefault()
      ;['open', 'close'].map((type) => {
        let mondayOpenHour = document.querySelector(
          `#location_contact_detail_attributes_opening_hours_attributes_1_${type}_4i`
        )
        let mondayOpenMinute = document.querySelector(
          `#location_contact_detail_attributes_opening_hours_attributes_1_${type}_5i`
        )
        ;[2, 3, 4, 5].map((day) => {
          let open_hour_input = document.querySelector(
            `#location_contact_detail_attributes_opening_hours_attributes_${day}_${type}_4i`
          )
          let open_minute_input = document.querySelector(
            `#location_contact_detail_attributes_opening_hours_attributes_${day}_${type}_5i`
          )
          open_hour_input.value = mondayOpenHour.value
          open_minute_input.value = mondayOpenMinute.value
        })
      })
    })
  }

  // Initial check to hide the copy button if Monday's status is 'By Appointment'
  let mondayStatus = document.querySelector('[target="day-1"]')?.value
  if (mondayStatus === 'By Appointment') {
    copyHoursBtn.classList.add('d-none')
  }
})

function setCookie(name, value, days) {
  var expires = ''
  if (days) {
    var date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
    expires = '; expires=' + date.toUTCString()
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/'
}

ready(function () {
  let collapse = document.getElementById('collapse-sidemenu')
  let sidebar = document.getElementById('sidebar')
  if (collapse) {
    collapse.addEventListener('click', (e) => {
      e.preventDefault()
      sidebar.classList.toggle('collapsed')
      if (sidebar.classList.contains('open')) {
        setCookie('sidebar', 'collapsed')
      } else {
        setCookie('sidebar', 'open')
      }
    })
  }
})

ready(function () {
  let logContainer = document.getElementById('log-container')
  if (logContainer) {
    logContainer.scrollTop = logContainer.scrollHeight
  }
})

// add supascript / subscript to TRIX editor
//var Trix = require("trix")
//require("@rails/actiontext")

Trix.config.textAttributes.sup = { tagName: 'sup', inheritable: true }
Trix.config.textAttributes.sub = { tagName: 'sub', inheritable: true }

addEventListener('trix-initialize', function (event) {
  var buttonHTML, buttonGroup

  buttonHTML =
    '<button class="trix-button" type="button" data-trix-attribute="sup"><sup>SUP</sup></button>'
  buttonHTML +=
    '<button class="trix-button" type="button" data-trix-attribute="sub"><sub>SUB</sub></button>'

  buttonGroup = event.target.toolbarElement.querySelector(
    '.trix-button-group.trix-button-group--text-tools'
  )
  buttonGroup.insertAdjacentHTML('beforeend', buttonHTML)
})

ready(function () {
  let collapseExpand = document.querySelectorAll('.collapse-expand')
  if (collapseExpand) {
    collapseExpand.forEach((collapseExpand) =>
      collapseExpand.addEventListener('click', (e) => {
        e.preventDefault()
        let target = e.target.getAttribute('target')
        if (!target) {
          target = e.target.parentElement.getAttribute('target')
          e.target.classList.toggle('rotate-180')
        } else {
          e.target.querySelector('.fa').classList.toggle('rotate-180')
        }
        $(`#${target}`).toggle()
      })
    )
  }
})

ready(function () {
  let location_sync_check = document.getElementById('location_keep_in_sync_with_gmb')
  if (location_sync_check) {
    location_sync_check.addEventListener('change', (e) => {
      let section_to_hide = document.getElementById('hide-if-synced-with-google-place')
      let section_to_show = document.getElementById('show-if-synced-with-google-place')
      if (e.currentTarget.checked) {
        section_to_hide.classList.add('d-none')
        section_to_show.classList.remove('d-none')
      } else {
        section_to_hide.classList.remove('d-none')
        section_to_show.classList.add('d-none')
      }
    })
  }
})

ready(function () {
  let openMenu = document.querySelector('.open-menu')
  if (openMenu) {
    openMenu.addEventListener('click', (e) => {
      let sidebar = document.querySelector('.sidebar')
      if (sidebar) {
        sidebar.classList.toggle('openSidebar')
      }
    })
  }
})

// This function is used to "select all" photos on the car photos page
ready(function () {
  let selectAllPhotos = document.querySelector('#select-all-photos')
  if (selectAllPhotos) {
    selectAllPhotos.addEventListener('click', function (e) {
      e.preventDefault()
      // I want to toggle the text between "Select All" and "Unselect All"
      selectAllPhotos.innerHTML == 'Unselect Remove All'
        ? (selectAllPhotos.innerHTML = 'Select Remove All')
        : (selectAllPhotos.innerHTML = 'Unselect Remove All')
      let inputs = document.querySelectorAll('input[id^="car_photos_attributes"]')
      inputs.forEach((el) => {
        el.checked = !el.checked
      })
    })
  }
})

// Function: Open Dealer Studio Modal
function openDsModal(heading, body) {
  let modal_body = Rails.$('#exampleModal .modal-body')[0]
  document.querySelector('#exampleModal .modal-dialog').classList.add('modal-lg')
  let modal_header = Rails.$('#exampleModal .modal-header')[0]
  if (heading) {
    modal_header.innerHTML = heading
  }
  if (body) {
    modal_body.innerHTML = body
  }
  $('#exampleModal').modal()
}

window.openDsModal = openDsModal

//function to hide or show apiKey token for dealers
function toggleTokenVisibility(button, tokenId, maskedToken, actualToken) {
  var tokenElement = document.getElementById(tokenId)

  if (tokenElement) {
    if (tokenElement.innerText === maskedToken) {
      tokenElement.innerText = actualToken
      button.innerHTML = '<i class="fas fa-eye-slash"></i>' // Change icon to eye-slash
    } else {
      tokenElement.innerText = maskedToken
      button.innerHTML = '<i class="fas fa-eye"></i>' // Change icon to eye
    }
  }
}

window.toggleTokenVisibility = toggleTokenVisibility

ready(() => {
  toggleTokenVisibility()
})
