import { Carousel } from 'primereact/carousel'
import { Chip } from 'primereact/chip'
import { MeterGroup } from 'primereact/metergroup'
import { ProgressBar } from 'primereact/progressbar'
import { ProgressSpinner } from 'primereact/progressspinner'
import { classNames } from 'primereact/utils'
import { useEffect, useState } from 'react'
import {
  canPlayVideo,
  formatBytes,
  MAX_VIDEO_FILE_SIZE,
  useVideoCompression,
} from '../../FileValidations'
import { FormGroup } from './FormGroup'
import InputLabel from './InputLabel'

const VideoTemplate = ({ file, url, ...props }) => {
  const cleanUrl = url.includes('blob') ? url.replace(/\?.+/gm, '') : url
  return (
    <video {...props} controls loop autoPlay muted src={cleanUrl}>
      <source src={cleanUrl} type={file?.type ?? 'video/webm'} />
    </video>
  )
}

export const LabeledFileInput = ({
  item,
  itemName,
  file,
  label,
  updateItem,
  customOnChange,
  accept,
  imageEndpoint,
  maxImageWidth,
  maxImageHeight,
  multiple = false,
  tooltip,
}) => {
  const [previewUrl, setPreviewUrl] = useState(file?.url || '')
  const [loading, setLoading] = useState(false)
  // This just calculates the total saved space from all files rather than  each individual file
  const [savedSpace, setsSavedSpace] = useState({ compression: 0, conversion: 0, original: 0 })
  const [abortController, setAbortController] = useState(null)
  const { state, optimizeFile } = useVideoCompression()
  const [error, setError] = useState(null)
  const isVideo = fileState?.file?.type?.includes('video') || canPlayVideo(previewUrl)

  const fileState = item[itemName] // {file:File; url:string} // undefined

  const handleCancel = () => {
    if (!!abortController) {
      abortController.abort()
    }
  }

  const handleFileChange = async (e) => {
    setError(null)
    const fileList = e.target.files
    if (fileList) {
      // Reset total saved MBs
      setsSavedSpace({ compression: 0, conversion: 0, original: 0 })
      const fileArray = Array.from(fileList).map((file, index) => ({ file, index }))
      const sequentialFiles = fileArray.filter(
        ({ file }) => file.type.includes('video') && file.size >= MAX_VIDEO_FILE_SIZE
      )
      const parallelFiles = fileArray.filter(
        ({ file }) => !file.type.includes('video') || file.size < MAX_VIDEO_FILE_SIZE
      )

      const processFile = async ({ file, index }, i) => {
        const controller = new AbortController()
        setAbortController(controller)
        try {
          // call optimizer with abort controller
          const { file: optimizedFile, stats } = await optimizeFile(file, controller)

          setsSavedSpace(({ compression, conversion, original }) => ({
            compression: compression + stats.compressedSize,
            conversion: conversion + stats.conversionSize,
            original: original + stats.originalSize,
          }))
          const blobUrl = URL.createObjectURL(optimizedFile)
          const updatedUrl = fileState?.[i]?.url ?? `${blobUrl}?type=${file.type}`

          return { file: optimizedFile, url: updatedUrl, index }
        } catch (err) {
          // FFMPEG doesn't close instantly so it can try to exec the next read
          if (controller?.signal?.aborted && err.message.includes('file')) {
            return { file, error: err?.message }
          }
          setError(state?.error ?? err?.message)
          return { file, error: err?.message }
        }
      }

      const parallelResults = await Promise.all(parallelFiles.map(processFile))

      // Handling large files sequentially since FFMPEG WASM cannot run in parallel
      // might be a bottle neck if we end up uploading 10+ videos
      const sequentialResults = await sequentialFiles.reduce(async (prevPromise, file, i) => {
        const accumulatedResults = await prevPromise
        const result = await processFile(file, i)
        return [...accumulatedResults, result]
      }, Promise.resolve([]))

      const getFiles = [...parallelResults, ...sequentialResults]
        .sort((a, b) => a.index - b.index)
        .filter((items) => !items.error)

      // Not using multiple return to previous output {file:File; url:string}
      const files = multiple ? getFiles : getFiles[0]

      if (customOnChange) {
        customOnChange(files)
      } else {
        updateItem({ ...item, [itemName]: files })
      }

      if (!files) return

      if (multiple) {
        setPreviewUrl(files.map(({ url }) => url))
        multipleUploads(files)
      } else {
        setPreviewUrl(files.url)
        upload(files.file)
      }
    }
  }

  const cloudinaryPostRequest = async (file, onlyGetResponse = false) => {
    const cloudinaryResponse = await new Promise((resolve, reject) => {
      const formData = new FormData()
      formData.append('image', file)

      const xhr = new XMLHttpRequest()
      xhr.open('POST', imageEndpoint)
      xhr.onreadystatechange = function () {
        if (xhr.readyState === XMLHttpRequest.DONE) {
          if (xhr.status === 200) {
            // File was uploaded successfully
            const response = JSON.parse(xhr.responseText)
            const previewUrl = response.file.url
            resolve(previewUrl)
          } else {
            // Handle error
            reject(new Error('Error uploading file: ' + xhr.status))
          }
        }
      }
      xhr.send(formData)
    })
    if (onlyGetResponse) return cloudinaryResponse
    console.log('Cloudinary response: ', cloudinaryResponse)
    return { file, url: cloudinaryResponse }
  }

  const multipleUploads = async (files) => {
    if (files) {
      setLoading(true)
      const filesWithUpdatedUrls = await Promise.all(
        files.map(async ({ file }) => await cloudinaryPostRequest(file))
      )
      setLoading(false)
      if (filesWithUpdatedUrls) {
        if (customOnChange) {
          customOnChange(filesWithUpdatedUrls)
        } else {
          updateItem({ ...item, [itemName]: filesWithUpdatedUrls })
        }
        alert(
          'Image successfully uploaded to Cloudinary: ' +
            filesWithUpdatedUrls.map(({ url }) => url).join('\n')
        )
      } else {
        alert('An error occured when uploading the image')
      }
    } else {
      alert('There is no file to upload')
    }
  }

  const upload = async (file) => {
    if (file) {
      setLoading(true)
      const cloudinaryResponse = await new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('image', file)

        const xhr = new XMLHttpRequest()
        xhr.open('POST', imageEndpoint)
        xhr.onreadystatechange = function () {
          if (xhr.readyState === XMLHttpRequest.DONE) {
            if (xhr.status === 200) {
              // File was uploaded successfully
              const response = JSON.parse(xhr.responseText)
              const previewFile = response.file
              resolve(previewFile)
            } else {
              // Handle error
              reject(new Error('Error uploading file: ' + xhr.status))
            }
          }
        }
        xhr.send(formData)
      })
      setLoading(false)
      console.log('Cloudinary response: ', cloudinaryResponse.url)
      if (cloudinaryResponse) {
        if (customOnChange) {
          customOnChange({
            file,
            url: cloudinaryResponse.url,
            width: cloudinaryResponse.width,
            height: cloudinaryResponse.height,
          })
        } else {
          updateItem({
            ...item,
            [itemName]: {
              file,
              url: cloudinaryResponse.url,
              width: cloudinaryResponse.width,
              height: cloudinaryResponse.height,
            },
          })
        }
        alert('Image successfully uploaded to Cloudinary: ' + cloudinaryResponse.url)
      } else {
        alert('An error occured when uploading the image')
      }
    } else {
      alert('There is no file to upload')
    }
  }

  const deleteFile = () => {
    updateItem({ ...item, [itemName]: { file: null, url: null, width: null, height: null } })
    setPreviewUrl('')
  }

  useEffect(() => {
    if (Array.isArray(file)) return setPreviewUrl(file.map(({ url }) => url))
    setPreviewUrl(file?.url || '')
  }, [file])

  useEffect(() => {
    if (state.thumbnail) setPreviewUrl(state.thumbnail)
  }, [state.thumbnail])

  const imageTemplate = (url) => (
    <div key={`preview-url-${url}`} className="w-100 h-100 d-flex justify-content-center">
      {canPlayVideo(url) ? (
        <VideoTemplate
          file={fileState?.file}
          className={maxImageHeight ? '' : 'w-100'}
          url={url.replace(/\?.*/, '')}
        />
      ) : (
        <img
          src={url.replace(/\?.*/, '')}
          crossOrigin="anonymous"
          alt={`Preview image for an uploaded file`}
          style={{ objectFit: 'contain', maxWidth: '100%' }}
        />
      )}
    </div>
  )

  return (
    <FormGroup>
      <InputLabel label={label} itemName={itemName} tooltip={tooltip} />
      <input
        id={itemName}
        className="form-control"
        type="file"
        accept={accept}
        onChange={handleFileChange}
        multiple={multiple}
      />
      <div
        className={classNames(
          multiple && 'flex-column',
          'd-flex justify-content-center align-items-center mt-2 mb-1 mx-auto'
        )}
        style={{ maxWidth: maxImageWidth ?? undefined, maxHeight: maxImageHeight ?? undefined }}
      >
        {previewUrl &&
          (Array.isArray(previewUrl) ? (
            <Carousel
              value={previewUrl}
              numVisible={1}
              numScroll={1}
              itemTemplate={imageTemplate}
            />
          ) : isVideo ? (
            <VideoTemplate
              file={fileState?.file}
              className="w-100"
              url={previewUrl.replace(/\?.*/, '')}
              style={{ objectFit: 'contain' }}
            />
          ) : (
            <img
              className="w-100"
              src={previewUrl.replace(/\?.*/, '')}
              alt="uploaded file"
              crossOrigin="anonymous"
              style={{ objectFit: 'contain' }}
            />
          ))}
      </div>
      <div className="w-100">
        {!!savedSpace.compression && fileState?.url && (
          <MeterGroup
            max={savedSpace.original}
            values={[
              savedSpace.conversion
                ? {
                    value: savedSpace.conversion,
                    label: 'Optimizations',
                    color: 'var(--info)',
                    textColor: '#ffffff',
                  }
                : {},
              {
                value: savedSpace.compression,
                label: 'Compressed',
                color: 'var(--primary)',
                textColor: '#ffffff',
              },
            ]}
            labelList={({ values }) => (
              <div className="mt-2">
                {values.map(({ color, value, label, textColor }) =>
                  !!label ? (
                    <Chip
                      className="mr-2"
                      label={`${label} ${formatBytes(value)}`}
                      style={{
                        backgroundColor: color,
                        color: textColor,
                      }}
                    />
                  ) : null
                )}
                <Chip
                  className="mr-2"
                  label={`Original size ${formatBytes(savedSpace.original)}`}
                />
              </div>
            )}
          />
        )}
      </div>
      {!!state.ffmpegRunning && (
        <>
          <ProgressBar
            value={state.progress}
            mode={state.progress > 1 ? 'determinate' : 'indeterminate'}
          />
          <div className="d-flex flex-wrap my-2" style={{ gap: '0.5rem' }}>
            <span className="btn btn-sm btn-outline-secondary" style={{ borderRadius: '16px' }}>
              <ProgressSpinner
                style={{ width: '12px', height: '12px' }}
                className="mr-2"
                aria-label="Compressing File"
                stroke="var(--secondary)"
              />
              {state.step}
            </span>
            <button
              className="btn btn-sm btn-outline-danger"
              style={{ borderRadius: '16px' }}
              onClick={handleCancel}
            >
              Cancel
            </button>
          </div>
        </>
      )}
      {loading && (
        <span className={`my-2 btn btn-sm btn-outline-success`} style={{ borderRadius: '16px' }}>
          <ProgressSpinner
            style={{ width: '12px', height: '12px' }}
            className="mr-2"
            aria-label="Uploading File"
            stroke="var(--success)"
          />
          Uploading File...
        </span>
      )}
      {!!error && (
        <span className={`my-2 btn btn-sm btn-outline-danger`} style={{ borderRadius: '16px' }}>
          Error: {error}
        </span>
      )}
      {!loading && !state.ffmpegRunning && fileState?.url && (
        <>
          <button
            className={`my-2 btn btn-sm btn-outline-danger`}
            style={{ borderRadius: '16px' }}
            onClick={deleteFile}
          >
            Delete {isVideo ? 'Video' : 'Image'}
          </button>
        </>
      )}
    </FormGroup>
  )
}
