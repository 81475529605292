import { useEffect, useState } from 'react'
import { deparameterize, generateRandomId } from '../../common/Utils'
import { filterFields } from './formBuilderUtils'
import { fetchData } from '../../templates/templateUtils'
import RadioCardInput from './CustomInputs/RadioCards'

export const InputPreview = ({ item, disabled = false, locationOptions }) => {
  let placeholder = item.placeholder
  if (disabled) {
    placeholder =
      item.name === 'name'
        ? 'Jeremy Lynch'
        : item.name === 'email'
          ? 'jeremy@totaldealer.com.au'
          : item.name === 'phone'
            ? '0411 222 333'
            : item.placeholder
  }

  // * Handles formatting for splitting full name into first and last name
  if (item.name === 'name') {
    return (
      <div className="form-group">
        <div className="row">
          <div className="col-12 col-md-6">
            <label htmlFor={item.name} className="form-label">
              First Name
              {item.required && <span className="text-danger">*</span>}
            </label>
            <input
              id={item.name}
              className="form-control"
              placeholder="First Name"
              disabled={disabled}
            />
          </div>
          <div className="col-12 col-md-6">
            <label htmlFor={`last_${item.name}`} className="form-label">
              Last Name
              {item.required && <span className="text-danger">*</span>}
            </label>
            <input
              id={`last_${item.name}`}
              className="form-control"
              placeholder="Last Name"
              disabled={disabled}
            />
          </div>
        </div>
      </div>
    )
  }

  if (item.type === 'hidden') {
    return null
  }

  if (item.type === 'select')
    return (
      <div className="form-group">
        <label htmlFor={item.name} className="form-label">
          {item.label}
          {item.required && <span className="text-danger">*</span>}
        </label>
        <select
          id={item.name}
          className="form-control"
          placeholder={placeholder}
          disabled={disabled}
        >
          <option>{placeholder}</option>
          {item?.options?.map((option) => (
            <option key={`${item.name}-option-${generateRandomId(5)}`}>{option}</option>
          ))}
        </select>
      </div>
    )

  if (item.type === 'locationSelect') {
    return (
      <div className="form-group">
        <label htmlFor={item.name} className="form-label">
          {item.label}
          {item.required && <span className="text-danger">*</span>}
        </label>
        <select
          id={item.name}
          className="form-control"
          placeholder={placeholder}
          disabled={disabled}
        >
          <option>{placeholder}</option>
          {item?.options?.map((option) => (
            <option key={`${item.name}-${option}-${generateRandomId(5)}`}>
              {locationOptions?.filter((select) => `${select.value}` === option)[0]?.label}
            </option>
          ))}
        </select>
      </div>
    )
  }

  if (item.type === 'message') {
    return (
      <div className="form-group">
        <label htmlFor={item.name} className="form-label">
          {item.label}
          {item.required && <span className="text-danger">*</span>}
        </label>
        <textarea
          id={item.name}
          className="form-control"
          placeholder={placeholder}
          disabled={disabled}
        />
      </div>
    )
  }

  if (item.type === 'year') {
    const currentYear = new Date().getFullYear()

    const last_30_years = Array.from(
      { length: (1970 - currentYear) / -1 + 1 },
      (_, i) => currentYear + i * -1
    ).map((year) => {
      return { value: year, name: year }
    })
    return (
      <div className="form-group">
        <label htmlFor={item.name} className="form-label">
          {item.label}
          {item.required && <span className="text-danger">*</span>}
        </label>
        <select
          id={item.name}
          className="form-control"
          placeholder={placeholder}
          disabled={disabled}
        >
          <option>{placeholder}</option>
          {last_30_years?.map((option) => (
            <option key={`${item.name}-${option.name}-${generateRandomId(5)}`}>
              {option.name}
            </option>
          ))}
        </select>
      </div>
    )
  }

  if (item.type === 'make') {
    const [manufacturerOptions, setManufacturerOptions] = useState([])

    useEffect(() => {
      fetchData('manufacturers').then((data) => {
        setManufacturerOptions(
          data.map((manufacturer) => {
            return { name: manufacturer.name, value: manufacturer.slug }
          })
        )
      })
    }, [])

    return (
      <div className="form-group">
        <label htmlFor={item.name} className="form-label">
          {item.label}
          {item.required && <span className="text-danger">*</span>}
        </label>
        <select
          id={item.name}
          className="form-control"
          placeholder={placeholder}
          disabled={disabled}
        >
          <option>{placeholder}</option>
          {manufacturerOptions?.map((option) => (
            <option key={`${item.name}-${option.name}-${generateRandomId(5)}`}>
              {option.name}
            </option>
          ))}
        </select>
      </div>
    )
  }

  if (item.type === 'check') {
    return (
      <div className="form-group pl-4">
        <input
          id={item.name}
          className="form-check-input"
          type="checkbox"
          defaultChecked={item.defaultChecked ? item.defaultChecked : false}
        />
        <label htmlFor={item.name} className="form-label">
          {item.label}
          {item.required && <span className="text-danger">*</span>}
        </label>
      </div>
    )
  }

  if (item.type === 'file') {
    return (
      <div className="form-group d-flex flex-column">
        <label htmlFor={item.name} className="form-label">
          {item.label}
          {item.required && <span className="text-danger">*</span>}
        </label>
        <input id={item.name} type="file" />
      </div>
    )
  }

  if (item.type === 'rego') {
    return (
      <div className="row">
        <div className="col-12 col-md-6">
          <div className="form-group">
            <label htmlFor={item.name + '-input'} className="form-label">
              Vehicle Rego
              {item.required && <span className="text-danger">*</span>}
            </label>
            <input id={item.name + '-input'} className="form-control" placeholder="ABC 123" />
            <span className="small text-primary">I'm not sure...</span>
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="form-group">
            <label htmlFor={item.name + '-select'} className="form-label">
              Vehicle State
              {item.required && <span className="text-danger">*</span>}
            </label>
            <select id={item.name + '-select'} className="form-control">
              {['NSW', 'QLD', 'VIC', 'ACT', 'NT', 'SA', 'TAS', 'WA']?.map((option) => (
                <option key={`${item.name}-${option}-${generateRandomId(5)}`}>{option}</option>
              ))}
            </select>
          </div>
        </div>
        <div className="col-12">
          <button className="btn btn-primary btn-block">Find My Car</button>
        </div>
      </div>
    )
  }

  if (item.type === 'dateTime') {
    return (
      <div className="form-group">
        <label className="form-label">
          {item.dateTimeType
            ? `${deparameterize(item.dateTimeType)} Date & Time Inputs`
            : 'Date & Time Inputs'}
          {item.required && <span className="text-danger">*</span>}
        </label>
        <p className="font-italic mb-0">
          There is no preview for this input. On your live page, this input will appear as a
          calendar with your available booking times, followed by a series of time options.
        </p>
      </div>
    )
  }

  if (item.type === 'currency') {
    return (
      <div className="form-group">
        <label htmlFor={item.name} className="form-label">
          {item.label}
          {item.required && <span className="text-danger">*</span>}
        </label>
        <input
          id={item.name}
          className="form-control"
          placeholder={placeholder}
          disabled={disabled}
        />
        {item?.options?.length > 0 && (
          <div className="mt-2">
            {item.options?.map((option, index) => (
              <div
                key={`option-${index}`}
                className={
                  'btn btn-sm btn-outline-secondary mr-2 mb-2 btn-money' // btn-money added for dark mode
                }
              >
                {option}
              </div>
            ))}
          </div>
        )}
        {item?.help && <span className="form-text text-muted small">{item?.help}</span>}
      </div>
    )
  }

  if (item.type === 'radio') {
    return (
      <div className="form-group">
        <label className="form-label">
          {item.label}
          {item.required && <span className="text-danger">*</span>}
        </label>
        <div className="d-flex flex-wrap">
          {item?.options?.map((option, index) => (
            <div key={`option-${index}`} className="form-check form-check-inline mr-3">
              <input
                id={`${item.name}-${option}`}
                className="form-check-input"
                type="radio"
                name={item.name}
                value={option}
                defaultChecked={item.defaultSelected === option}
              />
              <label htmlFor={`${item.name}-${option}`} className="form-check-label">
                {option}
              </label>
            </div>
          ))}
        </div>
      </div>
    )
  }

  if (item.type === 'radioCard') {
    return <RadioCardInput item={item} />
  }

  return (
    <div className="form-group">
      <label htmlFor={item.name} className="form-label">
        {item.label}
        {item.required && <span className="text-danger">*</span>}
      </label>
      <input
        id={item.name}
        className="form-control"
        placeholder={placeholder}
        disabled={disabled}
      />
    </div>
  )
}

const FullFormPreview = ({ type, heading, description, fields, steps, locationOptions }) => {
  const [activeStep, setActiveStep] = useState(0)
  const [forceRerender, setForceRerender] = useState(false)

  const totalSteps =
    steps?.filter((step) => {
      if (fields.filter((field) => field.stepKey === step.key).length > 0 || step.isCustom)
        return step
    })?.length + 1
  const headingPreview =
    type === 'single' ? heading || undefined : steps[activeStep]?.heading || undefined
  const descriptionPreview =
    type === 'single' ? description || undefined : steps[activeStep]?.description || undefined

  // Force inputs re-render on first load
  // Fixes a strange bug with embedded forms not having their editorjs menus show up
  useEffect(() => {
    setForceRerender(true)
  }, [])

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="rounded border bg-white">
            {type === 'multi' && steps.length > 1 && (
              <div className="px-4 pt-4">
                <div className="rounded w-100 overflow-hidden bg-light" style={{ height: 20 }}>
                  <div
                    className="h-100 bg-primary"
                    style={{ width: `calc(100% * (${(activeStep + 1) / totalSteps}))` }}
                  ></div>
                </div>
                <p className="mt-2 mr-1 mb-0 text-muted text-right">
                  Step <strong className="font-weight-bold">{activeStep + 1}</strong> of&nbsp;
                  <strong className="font-weight-bold">{steps.length}</strong>
                </p>
              </div>
            )}
            {headingPreview || descriptionPreview ? (
              <div className="p-4">
                {headingPreview && <h3>{headingPreview}</h3>}
                {descriptionPreview && <p>{descriptionPreview}</p>}
              </div>
            ) : (
              <div className="pt-4"></div>
            )}
            {forceRerender && (
              <div className="px-4">
                {steps[activeStep].isCustom ? (
                  <p className="mb-3 text-danger">
                    This is a custom step, no preview is available.
                  </p>
                ) : (
                  fields?.length > 0 &&
                  filterFields(fields, type, steps[activeStep]?.key, true).map((field) => {
                    return (
                      <InputPreview
                        key={`input-preview-${generateRandomId(5)}`}
                        item={field}
                        locationOptions={locationOptions}
                      />
                    )
                  })
                )}
              </div>
            )}
            <div className="d-flex w-100 p-4">
              {type === 'multi' && activeStep > 0 && (
                <button
                  className="btn btn-primary btn-lg mr-auto ml-0"
                  onClick={() => {
                    setActiveStep(activeStep - 1)
                  }}
                >
                  Previous
                </button>
              )}
              <button
                className="btn btn-primary btn-lg ml-auto mr-0"
                onClick={() => {
                  if (type === 'multi') {
                    if (activeStep < steps.length - 1) {
                      setActiveStep(activeStep + 1)
                    }
                  }
                }}
              >
                {activeStep === steps.length - 1 || type === 'single' ? 'Submit Form' : 'Next'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FullFormPreview
