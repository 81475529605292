import { API, BlockAPI } from '@editorjs/editorjs'

import { Accordion, AccordionTab } from 'primereact/accordion'
import { InputSwitch } from 'primereact/inputswitch'
import { Message } from 'primereact/message'
import { classNames } from 'primereact/utils'
import { useEffect, useMemo, useRef, useState } from 'react'
import { createRoot } from 'react-dom/client'
import { Mode } from 'vanilla-jsoneditor'
import { Input, Select } from '../../../entries/FormElements'
import { LabeledInput } from '../../common'
import { FormGroup } from '../../common/FormGroup'
import JSONEditorReact from '../../common/JsonEditor'
import { handleEmbeddedEditorActions } from '../../common/Utils'
import type { DefaultConfigProps, NodesProps, ToolboxProps } from '../../types/toolData.type'
import {
  type CustomComponentDataProps,
  CustomInputs,
  fetchComponentOptions,
  JSONStructure,
  OPTIONS,
} from './customComponentUtils'

const DEFAULT_JSON = [{ key: 'My prop key', value: 'value', type: 'string' }]

const RenderedFormComponent = ({
  data,
  onDataChange,
  isAdmin,
  templateType,
  editorApi,
  imageEndpoint,
}) => {
  const [showRaw, setShowRaw] = useState(false)
  const [state, setState] = useState(data)

  // Default to customComponentProps text if available
  let defaultText = data?.customComponentProps?.text
  if (!defaultText) {
    if (Array.isArray(data?.rawProps) && data?.rawProps?.length > 0) {
      // Next, if the block is using an array (rawProps), apply it
      defaultText = JSON.stringify(data?.rawProps)
    } else {
      // Otherwise, apply the JSON structure of customComponentProps (or an empty object if undefined)
      defaultText = JSON.stringify(
        data?.customComponentProps?.json ?? data?.customComponentProps ?? {}
      )
    }
  }

  const [json, setJson] = useState<{ json?: unknown[]; text?: string }>({
    text: defaultText,
  })

  const embeddedRef = useRef(null)
  const [error, setError] = useState(null)

  const parsedJson = useMemo<JSONStructure[]>(() => {
    try {
      setError(null)
      const { json: rawJson, text } = json
      return rawJson ?? JSON.parse(text) ?? DEFAULT_JSON
    } catch (err) {
      setError(`Invalid JSON structure: ${(err as Error).message}`)
      const { text } = json
      return text ?? DEFAULT_JSON
    }
  }, [json])

  const isRaw = typeof parsedJson === 'string'

  const updateState = (updatedValue: Object) => {
    const updatedValues = { ...state, ...updatedValue }
    setState(updatedValues)
    onDataChange(updatedValues)
  }

  function setUniqueComponentId(value: string) {
    updateState({ uniqueComponentId: value })
  }

  function setCustomComponentId(e: any) {
    updateState({ customComponentId: e.target.value })
  }

  const updateJson = (
    { target: { name, value } }: { target: { name: string; value: string } },
    index
  ) => {
    if (error) return
    // Format JSON with types
    const updatedJSON = parsedJson.map((input, i) => {
      if (index !== i) return input

      const updatedValue = () => {
        switch (input.type) {
          case 'number':
            return !!Number(value) ? Number(value) : value
          case 'object': {
            try {
              if (typeof value === 'object') return value
              else return JSON.parse(value)
            } catch (err) {
              return value
            }
          }
          default:
            return value
        }
      }

      const defaultValues = () => {
        if (name !== 'type') return
        switch (value) {
          case 'number':
            return 0
          case 'object':
            return { foo: 'bar' }
          case 'boolean':
            return true
          case 'image':
            return ''
          default:
            return ['my value']
        }
      }

      return {
        ...input,
        ...{ value: name === 'type' ? defaultValues() : input.value },
        [name.toLowerCase()]: updatedValue(),
      }
    })

    const updatedFormatting = updatedJSON.reduce((prev, { key, value }) => {
      prev[key] = Array.isArray(value) ? value.flat() : value
      return prev
    }, {})

    setJson({ text: JSON.stringify(updatedJSON), json: updatedJSON })
    updateState({ rawProps: updatedJSON, customComponentProps: updatedFormatting })
  }

  useEffect(() => {
    // Show the raw JSON when an error occurred
    if (error && !showRaw) setShowRaw(true)
  }, [error])

  useEffect(() => {
    handleEmbeddedEditorActions(embeddedRef.current, editorApi)
    if (!parsedJson) setJson({ text: JSON.stringify(DEFAULT_JSON) })
    if (Array.isArray(parsedJson) || data?.rawProps) return

    const oldFormat = parsedJson as { text?: string; json?: object }
    const isOldFormat = !!oldFormat?.json || !!oldFormat?.text

    const parsedOldJson = isOldFormat
      ? oldFormat?.text
        ? JSON.parse(oldFormat?.text)
        : oldFormat?.json
      : parsedJson

    const getTypeOf = (value) => {
      if (typeof value !== 'object') return typeof value
      if (Array.isArray(value)) return 'array'
      return 'object'
    }

    const formattedJSON = JSON.stringify(
      Object.entries(parsedOldJson).map(([key, value]) => ({
        key,
        value,
        type: getTypeOf(value),
      }))
    )

    // 'Raw' JSON is unparsable JSON and will automatically tab over to rawJSON
    setJson({
      text: isRaw
        ? (parsedJson as string).length > 0
          ? parsedJson
          : JSON.stringify(DEFAULT_JSON)
        : formattedJSON,
    })
  }, [])

  const hasDuplicateKey = (key, currentIndex) => {
    const keys = parsedJson.map((obj) => {
      const [keyPair] = Object.entries(obj)
      if (keyPair[0] === 'key') return keyPair[1]
    })
    return keys.some((v, i) => v === key && i !== currentIndex)
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="border rounded shadow-sm pt-3 px-3 bg-white">
            <div className="d-flex flex-column text-center mb-2 mx-0 mx-lg-5">
              <div>
                <p className="badge badge-warning mb-1">Warning</p>
              </div>
              <span className="small">
                This component is used to implement custom components that aren't customisable in
                the editor. All tune customisations are available, but each component may only be
                able to utilise specific ones. Please contact customer support if you are having any
                issues and need help.
              </span>
            </div>
            <div className="form-group">
              <label htmlFor="unique-component-id-select">Component</label>
              <select
                id="unique-component-id-select"
                className="form-control"
                defaultValue={data.uniqueComponentId ?? 'placeholder'}
                onChange={(e) => setUniqueComponentId(e.target.value)}
              >
                <option disabled value="placeholder">
                  Select a component
                </option>
                {fetchComponentOptions(templateType).map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
                <option value="custom">Custom</option>
              </select>
            </div>
            {state?.uniqueComponentId === 'custom' && (
              // @ts-expect-error JS props
              <LabeledInput
                controlled={false}
                item={state}
                itemName="customComponentId"
                label="Custom Component"
                placeholder="Enter the custom component ID"
                customOnChange={setCustomComponentId}
              />
            )}
            {isAdmin && state?.uniqueComponentId === 'custom' && (
              <Accordion className="pb-3">
                <AccordionTab
                  header={
                    <span className="d-flex align-items-center gap-2 w-full">
                      <span className="text-dark font-bold white-space-nowrap">
                        Custom Component Props
                      </span>
                    </span>
                  }
                >
                  <FormGroup>
                    <div className="d-flex justify-content-end py-2 pr-3" ref={embeddedRef}>
                      {/* @ts-ignore */}
                      <span className="d-flex align-items-center">
                        <span className="mr-2">Show raw JSON</span>
                        <InputSwitch checked={showRaw} onChange={() => setShowRaw((v) => !v)} />
                      </span>
                    </div>
                    {error && <Message className="w-100 my-2" severity="error" text={error} />}
                    {!showRaw && (
                      <div className={classNames(showRaw && 'd-none', 'pr-3')}>
                        {Array.isArray(parsedJson) &&
                          parsedJson.map(({ key, value, type }, i) => {
                            const isDuplicate = hasDuplicateKey(key, i)
                            return (
                              <div
                                className="row flex-wrap flex-lg-nowrap"
                                key={`custom-props-index-${i}`}
                              >
                                <div className="form-group col-12 col-lg-2">
                                  <Input
                                    wrapperClass="mb-0"
                                    className={classNames(
                                      'form-control string required',
                                      isDuplicate && 'is-invalid'
                                    )}
                                    label="Key"
                                    required
                                    value={key}
                                    onChange={(e) => updateJson(e, i)}
                                  />
                                  {isDuplicate && (
                                    <span
                                      className="text-danger px-0"
                                      style={{ bottom: 0, fontSize: 'xx-small' }}
                                    >
                                      Duplicate key.
                                    </span>
                                  )}
                                </div>
                                <Select
                                  options={OPTIONS}
                                  wrapperClass="form-group col-12 col-lg-2 pl-3 pl-lg-0"
                                  label="Type"
                                  required
                                  value={OPTIONS.find(({ value }) => value === type)}
                                  onChange={({ value }) =>
                                    updateJson({ target: { name: 'type', value } }, i)
                                  }
                                  hint={undefined}
                                />
                                <CustomInputs
                                  type={type}
                                  wrapperClass="form-group w-100"
                                  label="Value"
                                  required
                                  value={value}
                                  onChange={(e) => updateJson(e, i)}
                                  state={{ key, value, type }}
                                  imageEndpoint={imageEndpoint}
                                  editorApi={editorApi}
                                />
                                <div className="col-12 col-lg-1 d-flex justify-content-end pt-lg-4 flex-end">
                                  <button
                                    style={{ height: '38px', marginTop: '8px' }}
                                    type="button"
                                    className="w-100 btn btn-sm btn-outline-danger"
                                    onClick={() => {
                                      setJson({
                                        text: JSON.stringify([
                                          ...parsedJson.filter((_, index) => index !== i),
                                        ]),
                                      })
                                    }}
                                  >
                                    X
                                  </button>
                                </div>
                                <hr className="w-100 b-1 border-bottom d-block d-lg-none mx-3" />
                              </div>
                            )
                          })}
                        <div className="d-flex w-100 align-items-center justify-content-end flex-end pb-2">
                          <button
                            style={{ flex: 0.333 }}
                            type="button"
                            className="btn btn-sm btn-outline-primary"
                            onClick={() => {
                              const updatedJson = [
                                ...parsedJson,
                                { key: 'example', value: 'test', type: 'string' },
                              ]

                              setJson({ text: JSON.stringify(updatedJson) })
                              updateState({
                                customComponentProps: updatedJson,
                              })
                            }}
                          >
                            Add Another Property
                          </button>
                        </div>
                      </div>
                    )}
                    {/* Using the same D-none technique doesn't trigger updateProps on the editor */}
                    {showRaw && (
                      <JSONEditorReact
                        id="customComponentProps"
                        className="pr-3"
                        // Updating Mode causes an error within vanilla-jsoneditor
                        mainMenuBar={false}
                        mode={Mode.text}
                        content={{
                          // @ts-ignore JSON types not lining up
                          json: !isRaw ? parsedJson : undefined,
                          // @ts-ignore becomes a string when error is resolved for a split second
                          text: error || isRaw ? parsedJson : undefined,
                        }}
                        onChange={(content) => setJson(content as unknown)}
                        editorApi={editorApi}
                      />
                    )}
                  </FormGroup>
                </AccordionTab>
              </Accordion>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

class CustomComponentTool {
  private config: DefaultConfigProps & { isAdmin: boolean }
  private editorApi: API
  private blockAPI: BlockAPI
  private data: CustomComponentDataProps
  private CSS: {
    wrapper: string
  }
  private nodes: NodesProps

  static get toolbox(): ToolboxProps {
    return {
      title: 'Custom Component',
      icon: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-circle-alert">
          <circle cx="12" cy="12" r="10"/><line x1="12" x2="12" y1="8" y2="12"/>
          <line x1="12" x2="12.01" y1="16" y2="16"/>
        </svg>`,
    }
  }

  constructor({ data, config, api, block }) {
    this.config = config
    this.editorApi = api
    this.blockAPI = block

    let defaultData = {
      uniqueComponentId: undefined,
      customComponentId: undefined,
      // existing custom components will be undefined
      customComponentProps: undefined,
      rawProps: undefined,
    }

    this.data = Object.keys(data).length ? data : defaultData

    this.CSS = {
      wrapper: 'custom-component-tool',
    }

    this.nodes = {
      holder: null,
    }
  }

  render() {
    const rootNode = document.createElement('div')
    rootNode.setAttribute('class', this.CSS.wrapper)
    this.nodes.holder = rootNode

    const onDataChange = (newData: object) => {
      this.data = {
        ...this.data,
        ...newData,
      }
      this.config.save()
      // Force editor onChange event
      this.blockAPI.dispatchChange()
    }

    const root = createRoot(rootNode)
    root.render(
      <RenderedFormComponent
        onDataChange={onDataChange}
        data={this.data}
        templateType={this.config.templateType}
        isAdmin={this.config.isAdmin}
        editorApi={this.editorApi}
        // @ts-ignore
        imageEndpoint={this.config.imageUrl}
      />
    )

    return this.nodes.holder
  }

  save() {
    return this.data
  }
}

export default CustomComponentTool
