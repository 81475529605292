import Choices from 'choices.js/public/assets/scripts/choices.js'
import { ready } from './utils'

// This global variable allows us to access the Choices instances from anywhere in the app, and set their status
window.AppChoices = {}

function initChoices() {
  const elements = document.querySelectorAll('.js-choice')
  if (elements.length > 0) {
    ;[...elements].forEach((element) => {
      const choiceInstance = new Choices(element, {
        placeholder: true,
        itemSelectText: 'Select',
        addItems: false,
        type: 'select-multiple',
        allowHTML: true,
      })
      // Store each instance using its element ID or a fallback unique identifier
      window.AppChoices[element.id || `choice_${Object.keys(window.AppChoices).length}`] =
        choiceInstance
    })
  }
  const selects = document.querySelectorAll('.js-choice-select')
  if (selects.length > 0) {
    ;[...selects].map(
      (element) =>
        new Choices(element, {
          placeholder: true,
          itemSelectText: 'Select',
          allowHTML: true,
        })
    )
  }
}

window.initChoices = initChoices

ready(function () {
  initChoices()
})

document.addEventListener('vanilla-nested:fields-added', function (e) {
  initChoices()
})
