import { useEffect, useState } from 'react'
import { SelectButton } from 'primereact/selectbutton'
import { Slider } from 'primereact/slider'

import { frequencyOptions, handleColorType } from './utils'

import { capitalise } from '../../../entries/utils'
import { toCurrency } from '../../common/Utils'

export const FrequencySelectPreview = ({
  state,
  frequencyState,
  settingsPreview = false,
  handleChange,
}) => {
  const [filterPosition, setFilterPosition] = useState(`${frequencyState.default}`)

  useEffect(() => {
    setFilterPosition(`${frequencyState.default}`)
  }, [frequencyState])

  return (
    <div
      className={`col-12 col-md-6 ${
        frequencyState.active ? 'd-flex' : 'd-none'
      } flex-column align-items-center justify-content-start mb-4`}
    >
      <p className="text-center mb-2">Repayment frequency:</p>
      {/* Mobile */}
      <SelectButton
        id="frequency-select-button"
        value={settingsPreview ? `${frequencyState.default}` : filterPosition}
        className="d-flex flex-column d-md-none"
        onChange={(e) => {
          handleChange && handleChange(e.value)
          setFilterPosition(e.value)
        }}
        options={frequencyOptions.filter((option) => frequencyState.options.includes(option.value))}
        pt={{
          root: () => ({
            className: '',
            style: {
              borderRadius: '1rem',
              width: '100%',
              overflow: 'hidden',
            },
          }),
          button: ({ context }) => ({
            style: {
              backgroundColor: context.selected
                ? handleColorType(state.primaryColor)
                : handleColorType(state.secondaryColor),
              color: handleColorType(frequencyState.textColor),
              height: 38,
              border: 'none',
              boxShadow: 'none',
              padding: '1rem 5rem',
              width: '100%',
              borderRadius: '0',
              fontSize: '1rem',
            },
          }),
        }}
      />
      {/* Desktop */}
      <SelectButton
        id="frequency-select-button"
        className="d-none d-md-flex"
        value={settingsPreview ? `${frequencyState.default}` : filterPosition}
        onChange={(e) => {
          handleChange && handleChange(e.value)
          setFilterPosition(e.value)
        }}
        options={frequencyOptions.filter((option) => frequencyState.options.includes(option.value))}
        pt={{
          root: () => ({
            className: '',
            style: {
              borderRadius: '100rem',
              width: '100%',
              overflow: 'hidden',
            },
          }),
          button: ({ context }) => ({
            style: {
              backgroundColor: `${
                context.selected
                  ? handleColorType(state.primaryColor)
                  : handleColorType(state.secondaryColor)
              }`,
              color: handleColorType(frequencyState.textColor),
              height: 38,
              border: 'none',
              boxShadow: 'none',
              padding: '0.5rem 1rem',
              width: `calc(100% / ${frequencyState.options.length})`,
              fontSize: '0.75rem',
            },
          }),
        }}
      />
    </div>
  )
}

export const SliderPreview = ({ sliderValue, component, sliderState, handleChange, state }) => {
  let value = sliderValue ? sliderValue : sliderState.default
  if (component === 'amount') value = toCurrency(value)

  const formattedHeading = `Loan ${capitalise(component)}: ${value}${
    component === 'term' ? ' years' : component === 'interest' ? '%' : ''
  }`

  return (
    <div className={`col-12 col-md-6 mb-4 mb-md-5 ${sliderState.active ? 'd-block' : 'd-none'}`}>
      <p className="text-center">{formattedHeading}</p>
      <Slider
        value={
          sliderValue
            ? sliderValue
            : parseInt(sliderState.default) < parseInt(sliderState.min) ||
                parseInt(sliderState.default) > parseInt(sliderState.max)
              ? (parseInt(sliderState.min) + parseInt(sliderState.max)) / 2
              : parseInt(sliderState.default)
        }
        onChange={(e) => handleChange && handleChange(e.value)}
        step={parseFloat(sliderState.step) < 0.01 ? 0.01 : parseFloat(sliderState.step).toFixed(2)}
        min={
          parseInt(sliderState.min) < 0 || parseInt(sliderState.min) == 0
            ? 0
            : parseInt(sliderState.min)
        }
        max={
          parseInt(sliderState.max) < parseInt(sliderState.min)
            ? parseInt(sliderState.min)
            : parseInt(sliderState.max)
        }
        pt={{
          root: () => ({
            style: {
              background: handleColorType(state.secondaryColor),
            },
          }),
          range: () => ({
            style: {
              background: handleColorType(state.primaryColor),
            },
          }),
          handle: () => ({
            style: {
              marginTop: '-15px',
              height: 30,
              width: 30,
              borderRadius: '100%',
              backgroundColor: 'white',
              border: `5px solid ${handleColorType(state.primaryColor)}`,
              boxShadow: 'none',
            },
          }),
        }}
      />
    </div>
  )
}

const FinanceCalculatorPreview = ({ state, isModalOpen }) => {
  const [sliderValue, setSliderValue] = useState({
    amount: state.amount?.default || 30000,
    term: state.term?.default || 6,
    interest: state.interest?.default || 10,
  })

  const [frequency, setFrequency] = useState({
    name:
      frequencyOptions.filter((option) => `${option.value}` === `${state.frequency?.default}`)[0]
        .label || 'Weekly',
    value: state.frequency?.default || 52,
  })

  // Correctly update state values when changes are made
  useEffect(() => {
    setSliderValue({
      amount: state.amount?.default || 30000,
      term: state.term?.default || 6,
      interest: state.interest?.default || 10,
    })
    setFrequency({
      name:
        frequencyOptions.filter((option) => `${option.value}` === `${state.frequency?.default}`)[0]
          .label || 'Weekly',
      value: state.frequency?.default || 52,
    })
  }, [state])

  const calculatePayment = () => {
    const principal = sliderValue.amount
    const calculatedInterest = sliderValue.interest / 100 / frequency.value
    const calculatedPayments = sliderValue.term * frequency.value
    const x = Math.pow(1 + calculatedInterest, calculatedPayments)
    const payment = (principal * x * calculatedInterest) / (x - 1)

    return toCurrency(payment)
  }

  return (
    <div className="container text-center" key={isModalOpen}>
      <div className="row justify-content-center">
        <SliderPreview
          sliderValue={sliderValue.amount}
          component="amount"
          sliderState={state.amount}
          handleChange={(value) => setSliderValue({ ...sliderValue, amount: value })}
          state={state}
        />
        <SliderPreview
          sliderValue={sliderValue.term}
          component="term"
          sliderState={state.term}
          handleChange={(value) => setSliderValue({ ...sliderValue, term: value })}
          state={state}
        />
        <SliderPreview
          sliderValue={sliderValue.interest}
          component="interest"
          sliderState={state.interest}
          handleChange={(value) => setSliderValue({ ...sliderValue, interest: value })}
          state={state}
        />
        <FrequencySelectPreview
          state={state}
          frequencyState={state.frequency}
          handleChange={(value) =>
            setFrequency({
              name:
                frequencyOptions.filter((option) => `${option.value}` === `${value}`)[0].label ||
                'Weekly',
              value: value,
            })
          }
        />
      </div>
      <div className="mt-3">
        {state.repaymentsText && (
          <p className="text-center font-weight-bold mb-0" style={{ fontSize: '1.2rem' }}>
            {state.repaymentsText}
          </p>
        )}
        <p className="text-center font-weight-bold mb-0">
          <span
            style={{
              fontSize: '2.5rem',
              color: handleColorType(state.primaryColor),
            }}
          >
            {calculatePayment()}
          </span>
          <span className="small">
            {' '}
            per{' '}
            {frequencyOptions
              .filter((option) => `${option.value}` === `${frequency.value}`)[0]
              .label.replace('ly', '')}
            *
          </span>
        </p>
      </div>
      {state.disclaimer && <p className="small text-center mt-3 mb-0">{state.disclaimer}</p>}
    </div>
  )
}

export default FinanceCalculatorPreview
